import React from 'react';

const DisclaimerWarranties = () => {
  return (
    <>
      <span>
        UNLESS OTHERWISE STATED, ALL GOODS AND SERVICES OFFERED BY TRAVELOPOD
        ARE PROVIDED TO YOU ON AN "AS IS," "AS AVAILABLE" BASIS. TO THE FULLEST
        EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, TRAVELOPOD DISCLAIMS ALL
        REPRESENTATIONS AND WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, SUITABILITY FOR A PARTICULAR PURPOSE, TITLE,
        UNINTERRUPTED SERVICE, AND ANY WARRANTIES ARISING OUT OF A COURSE OF
        PERFORMANCE, DEALING OR TRADE USAGE FOR ALL GOODS AND SERVICES SOLD
        BY/THROUGH TRAVELOPOD. Applicable law in your jurisdiction may not allow
        the exclusion of implied warranties, so the above exclusions may not
        apply to you.
      </span>
    </>
  );
};

export default DisclaimerWarranties;
