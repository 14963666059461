import React from 'react';

const Laws = () => {
  return (
    <>
      <span>
        Some Traveler will be traveling to foreign countries, with different
        customs, standards, laws and risks than those Travelers are accustomed
        to. Traveler understands that he or she must be prepared to cope with
        the unexpected, with local customs and shortages, with the vagaries of
        weather, travel and mankind in general. As such, Traveler acknowledges
        and accepts the risks associated with travel in a foreign country and
        agrees to release and hold Travelopod harmless for any such problems
        experienced while participating in their Trip.
      </span>
      <p>
        All Travelers must obey the local laws and regulations of the countries
        they visit on their Trip. Travelopod is not liable or responsible for
        any damages, costs and/or added expenses incurred as a result of
        Traveler’s failure to obey any local, provincial or federal laws.
      </p>
    </>
  );
};

export default Laws;
