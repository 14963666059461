import React from 'react';

const SeverabilitySurvivability = () => {
  return (
    <>
      <span>
        If any provision, or portion of a provision, in these Terms and
        Conditions shall be unlawful, void, or for any reason unenforceable,
        then that provision shall be deemed severable and shall not affect the
        validity and enforceability of any remaining provisions. Traveler and
        Travelopod agree to substitute for such provision a valid provision
        which most closely approximates the intent and economic effect of such
        severed provision.
      </span>
      <p>
        Notwithstanding any other provisions of this these Terms and Conditions,
        or any general legal principles to the contrary, any provision of these
        Terms and Conditions that imposes or contemplates continuing obligations
        on a party will survive the expiration or termination of these Terms and
        Conditions.
      </p>
    </>
  );
};

export default SeverabilitySurvivability;
