import React from 'react';

const ForceMajeure = () => {
  return (
    <>
      <span>
        Travelopod shall not be responsible for failure to perform any of its
        obligations under this Agreement during any period in which such
        performance is prevented or delayed due to Force Majeure. “Force
        Majeure” refers to any event beyond Travelopod’ reasonable control,
        including but not limited to severe weather, fire, flood, mudslides,
        earthquakes, war, labor disputes, strikes, epidemics, World Health
        Organization’s advisories and/or alerts, Center for Disease Control’s
        advisories and/or alerts, U.S. State Department’s advisories and/or
        alerts, any order of any local, provincial or federal government
        authority, interruption of power Services, terrorism or any other causes
        beyond the control of Travelopod or deemed by Travelopod to constitute a
        danger to the safety and well-being of Travelers. Travelopod reserves
        the right to cancel any Services described in a Trip Itinerary due to
        Force Majeure.
      </span>
    </>
  );
};

export default ForceMajeure;
