import React from 'react';

const Documents = () => {
  return (
    <>
      <span>
        It is Traveler’s responsibility to verify they have all the necessary
        visas, passport, and vaccinations prior to travel. A full and valid
        passport is required for all persons traveling to any of the
        destinations outside the U.S. that we feature. You must obtain and have
        possession of a valid passport, all visas, permits and certificates, and
        vaccination certificates required for your entire Trip. Most out of
        international Trips (including India) require a passport valid until at
        least six (6) months beyond the scheduled end of your Itinerary.
        Non-U.S. citizens should contact the appropriate consular office for any
        requirements pertaining to their Trip.
      </span>
      <p>
        If you try to enter a country where visa is required, and you do not
        have the visa, it is possible that you will be imprisoned until there is
        an available flight to return you to your point of origin. India
        requires at least a Tourist Visa to visit.
      </p>
      <p>
        Some countries require you to be in possession of a return ticket or
        exit ticket and have sufficient funds, etc. Similarly, certain countries
        require that the Traveler produce evidence of insurance/repatriation
        coverage before it will issue a visa.
      </p>
      <p>
        You must carefully observe all applicable formalities and ensure that
        the surnames and forenames used for all passengers when making a booking
        and appearing in your travel documents (booking forms, travel tickets,
        vouchers, etc.), correspond exactly with those appearing on your
        passport, visas, etc.
      </p>
      <p>
        Further information on entry requirements can be obtained from the State
        Department, by phone (202) 647-5335 or access online at{' '}
        <a href="https://travel.state.gov/content/travel.html">
          //travel.state.gov/travel{' '}
        </a>{' '}
        or directly from the destination country's website. Requirements for
        traveling to India change frequently, often with little advance notice,
        and changes may be poorly advertised and inconsistently enforced.
        Travelers are urged to check the website of the Indian Embassy in
        Washington, D.C. (
        <a href="https://www.indianembassy.org/">
          https://www.indianembassy.org/
        </a>
        ) before any travel to India to review the most current information. We
        recommend that you check these before booking and also shortly before
        departure as requirements may change.
      </p>
      <p>
        Immunization requirements vary from country to country and even region
        to region. Up-to date information should be obtained from your local
        health department and consulate. You assume complete and full
        responsibility for, and hereby release Travelopod from, any duty of
        checking and verifying vaccination or other entry requirements of each
        destination, as well as all safety and security conditions of such
        destinations during the length of the proposed travel or extensions
        expected or unexpected. For State Department information about
        conditions abroad that may affect travel safety and security, go to
        <a href="https://travel.state.gov/content/travel/en/international-travel/before-you-go/your-health-abroad.html">
          //travel.state.gov/travel/travel_1744.html
        </a>
        , or contact them by phone at (202) 647-5335. For foreign health
        requirements and dangers, contact the U.S. Centers for Disease Control
        (CDC) at (404) 332-4559, use their fax information service at (404)
        332-4565, or go to{' '}
        <a href="https://wwwnc.cdc.gov/travel/">//wwwnc.cdc.gov/travel/</a>.
      </p>
      <p>
        It is your responsibility to ensure that you hold the correct, valid
        documents for the countries you are visiting and have obtained the
        necessary vaccinations, clearance to travel, and hold the necessary
        confirmations for medications required as we cannot be held liable for
        any illness, delays, compensation, claims and costs resulting from your
        failure to meet these requirements.
      </p>
      <p>
        WE CANNOT ACCEPT RESPONSIBILITY IF YOU ARE REFUSED PASSAGE ON ANY
        AIRLINE, CRUISE, TRANSPORT OR ENTRY INTO ANY COUNTRY DUE TO THE FAILURE
        ON YOUR PART TO CARRY OR OBTAIN THE CORRECT DOCUMENTATION. IF FAILURE TO
        DO SO RESULTS IN FINES, SURCHARGES, CLAIMS, FINANCIAL DEMANDS OR OTHER
        FINANCIAL PENALTIES BEING IMPOSED ON US, YOU WILL BE RESPONSIBLE FOR
        INDEMNIFYING AND REIMBURSING US ACCORDINGLY.
      </p>
      <p>
        Although most travel to participating destinations is completed without
        incident, travel to certain areas may involve greater risk than others.
        You assume sole responsibility for your own safety at any destination
        traveled to. Travelopod does not guarantee your safety at any time, and
        assumes no responsibility for gathering and/or disseminating information
        for you relating to risks associated with your destinations. BY OFFERING
        OR FACILITATING TRAVEL TO CERTAIN DESTINATIONS, WE DO NOT REPRESENT OR
        WARRANT THAT TRAVEL TO SUCH POINTS IS ADVISABLE OR WITHOUT RISK, AND WE
        SHALL NOT BE LIABLE FOR DAMAGES OR LOSSES THAT MAY RESULT FROM TRAVEL TO
        SUCH DESTINATIONS.
      </p>
      <p>
        <b>PASSPORT / VISA REQUIREMENTS </b> <br />
        FOR DOMESTIC TRAVEL: A valid government photo I.D. must be presented by
        all travelers in order to board domestic flights. Minors traveling
        domestically with parents generally do not need a photo I.D. If
        traveling with children less than two (2) years old, a birth certificate
        may be needed to confirm the infant's age.{' '}
        <a href="https://www.tsa.gov/travel/security-screening/identification">
          More Information
        </a>
        .
      </p>
      <p>
        FOR INTERNATIONAL TRAVEL: All travelers MUST be in possession of a valid
        government issued Passport or Identification. Travelers MUST also have
        the necessary documents, Visas, Transit visas, Schengen Visas and all
        other entry permits for all international ports of entry.
      </p>
      <p>
        In addition, your passport must be valid for 6 months after your return
        date when entering your destination. While sometimes we may be able to
        assist with visa and passport information, it is solely the
        responsibility of the passenger(s) to arrange for all documents needed
        to enter the country you are traveling to, or passing through in
        transit. Please note, a roundtrip or ongoing ticket may be required for
        certain international cities. If you are traveling one way, please
        verify with the airline or Consulate General to prevent any issues at
        time of boarding.
      </p>
      <ul className="inner-list">
        <li>
          <a href="https://travel.state.gov/content/travel/en/passports.html/">
            US Passport Holder Information
          </a>
        </li>
        <li>
          <a href="https://travel.gc.ca/travelling/documents/visas">
            Canadian Passport Holder Information
          </a>
        </li>
        <li>
          <a href="https://visacentral.com/?login=401601">
            Other Passport Holder
          </a>
        </li>
      </ul>
    </>
  );
};

export default Documents;
