import React from 'react';

const BestPriceGuarantee = () => {
  return (
    <>
      <span>
        To make a Best Price Guarantee claim, customers should email{' '}
        <a href="mailto:service@travelopod.com">service@travelopod.com</a>{' '}
        within 24 hours of booking with Travelopod and include the competitor
        name, rate details and a screenshot of the other offer. If within that
        space of time after having purchased your flight, you find the same
        flight at a lower price and are able to document this for us, you are
        entitled to have an amount refunded which is equal to the difference
        between your total paid amount and the lower price which you have found.
        By the same flight, we mean a flight or several flights for the same
        number of persons and for the same routes, using the same airline(s),
        flight numbers, travel time, number of stops, outbound and inbound dates
        and times.
      </span>
      <p>
        <strong>CHANGES AFTER PURCHASE:</strong> All changes made to the booking
        after purchase are restricted and are strictly subject to the airline
        fare rules. Flight dates & times are changeable subject to availability
        and upon payment of a change fee per person, plus any additional cost
        between the original total price paid and the lowest total price
        available for the new flight at the time the change is made. Any
        confirmation made beyond the time frame allowed may result in additional
        costs, of which you will be informed by e-mail. It is essential that you
        return the confirmation document, otherwise your initial reservation
        will stand.
      </p>
      <p>
        Any cancellation or alteration of your booking may result in costs that
        will be billed by our Suppliers, and our administrative costs may be
        added to these. In the event of an alteration, these costs will be
        billed to you. In the event of cancellation, these costs are deducted
        from the refund payable to you as per this agreement.
      </p>
      <p>
        The cancellation of your booking for whatever reason does not exempt you
        from paying all the sums that you owe to Travelopod. Any interrupted or
        shortened stay, or any Service not actually used by you, for whatever
        reason (in particular in the event that you fail to arrive in time to
        enjoy the Services) does not entitle you to a refund.
      </p>
      <p>
        As a general rule alterations, whatever their nature, are treated as a
        cancellation followed by a new booking, involving the relevant
        cancellation charges. These charges will be added to any costs charged
        by the Supplier, of which you will be informed before the alteration is
        made.
      </p>
      <p>
        <em>*California and Illinois Residents only:</em>
        <br />
        Upon cancellation of the transportation or travel services, where the
        Traveler is not at fault and has not canceled in violation of any terms
        and conditions previously clearly and conspicuously disclosed and agreed
        to by the Traveler, all sums paid to the seller of travel for services
        not provided will be promptly paid to the Traveler, unless the Traveler
        advises the seller of travel in writing, after cancellation. In
        California, this provision does not apply where the seller of travel has
        remitted the payment to another registered wholesale seller of travel or
        a carrier, without obtaining a refund, and where the wholesaler or
        provider defaults in providing the agreed-upon transportation or
        service. In this situation, the seller of travel must provide the
        Traveler with a written statement accompanied by bank records
        establishing the disbursement of the payment, and if disbursed to a
        wholesale seller of travel, proof of current registration of that
        wholesaler.
      </p>
      <ol>
        <li>
          <p>
            Cancellations and Alterations attributable to Travelopod <br />
            Particularly as a result of political or environmental changes,
            rescheduling of air routes and times and other circumstances outside
            our control, we have on occasions to make changes to and cancel
            confirmed bookings. While we always endeavor to avoid changes and
            cancellations, we must reserve the right to do so. Most changes are
            minor. Occasionally, we have to make a “significant change”. A
            significant change is a change made before departure which, taking
            account of the information you give us at the time of booking, we
            can reasonably expect to have a major affect on your confirmed Trip.
            Significant changes are likely to include the following changes when
            made before departure where applicable to your booking: a change of
            accommodation to that of a lower standard for the whole or a major
            part of the time you are away, a change of area of travel for the
            whole or a major part of the time, a change of outward departure
            time or overall length of time you are away of twelve or more hours
            or a change of departure point to one which is more inconvenient for
            you where your Trip includes flights arranged by us.
          </p>
          <p>
            If we have to make a significant change or cancel, we will notify
            you as soon as possible. We will endeavor to offer you alternative
            routing and/or dates and at least one option, which is of a similar
            standard and cost. If there is time to do so before departure, we
            will offer you the choice of the following options:
          </p>
          <p>
            Please note the above options are not available where any change
            made is a minor one. If we have to make a significant change or
            cancel we will pay you reasonable compensation depending on the
            circumstances and when the significant change or cancellation is
            notified to you subject to the following exceptions:
          </p>
          <p>
            Very rarely, we may be forced by "force majeure" to change or
            terminate your Trip after departure but before the scheduled end of
            your Trip. This is extremely unlikely but if this situation does
            occur, we regret we will be unable to make any refunds (unless we
            obtain any refunds from our suppliers), pay you any compensation, or
            meet any costs or expenses you incur as a result.
          </p>
          <ol>
            <li>accepting the changed arrangements or</li>
            <li>
              purchasing an alternative Trip from us, of a similar standard to
              that originally booked if available. We will endeavor to offer You
              at least one alternative Trip of equivalent standard for which You
              will not be asked to pay any more than the price of the original
              Trip. If this Trip is in fact cheaper than the original one, we
              will refund the price difference. If You do not wish to accept the
              Trip we specifically offer You, You may choose any of the other
              arrangements we have available. You must pay the applicable price
              of any such alternative arrangements. This will mean Your paying
              more if they are more expensive or receiving a refund if cheaper
              or
            </li>
            <li>
              canceling or accepting the cancellation in which case you will
              receive a full refund of all monies uou have paid to us.
            </li>
            <li>
              Compensation will not be payable and no liability beyond offering
              the above mentioned choices can be accepted where we are forced to
              make a change or cancel as a result of unusual and unforeseeable
              circumstances beyond our control, the consequences of which we
              could not have avoided even with all due care.
            </li>
            <li>
              No compensation will be payable and the above options will not be
              available if we cancel as a result of your failure to comply with
              any requirement of these booking conditions entitling us to cancel
              (such as paying on time).
            </li>
          </ol>
        </li>
        <li>
          <p>
            Cancellations and Alterations attributable to You <br />
            <strong>Important:</strong> All bookings of airline tickets, &
            vacation packages are non-refundable generally unless specifically
            described otherwise. Any alteration, transfer, or cancellation
            relating to airfare, or dynamic packages (flight + hotel) which
            affect the booked flight(s), will involve charges up to the price of
            the air tickets bought including, without limitation, all fees,
            taxes, and charges. All cancellations must be done over the phone
            only. We can accept refund requests only if the following conditions
            have been met:
          </p>
          <p>
            All refund requests are processed in a set format. Once your
            cancellation request has been submitted with our customer care
            agent, you will get an email notification acknowledging your request
            . This acknowledgement does not automatically qualify you for a
            refund. Based upon the fare rule we work with the airline or other
            involved suppliers to generate a waiver and process the refund if
            fare rules allow. Cancellation vary as per fare rules, Refunds may
            take up to 4-6 business weeks to process.
          </p>
          <ol>
            <li>
              Only if the fare rules provide for cancellation and refunds;
            </li>
            <li>
              It is not "no show" situation (most "no show" bookings are
              in-eligible for any waiver from suppliers for refund processing)
            </li>
            <li>
              we are able to receive waivers from suppliers to process the
              requested cancellation and refund
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
};

export default BestPriceGuarantee;
