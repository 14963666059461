import React from 'react';

import Eligibility from './Eligibility';
import Modification from './Modification';
import Payments from './Payments';
import Prices from './Prices';
import Cancellations from './Cancellations';
import Issuing from './Issuing';
import Insurance from './Insurance';
import Documents from './Documents';
import Accommodations from './Accommodations';
import Notices from './Notices';
import TravelDisclosures from './TravelDisclosures';
import Laws from './Laws';
import LegalCompliance from './LegalCompliance';
import MarketingMaterials from './MarketingMaterials';
import LimitationLiability from './LimitationLiability';
import DisclaimerWarranties from './DisclaimerWarranties';
import IndemnificationRelease from './IndemnificationRelease';
import ForceMajeure from './ForceMajeure';
import AirTransport from './AirTransport';
import Warranties from './Warranties';
import MedicalCondition from './MedicalCondition';
import Disputes from './Disputes';
import Expenses from './Expenses';
import Assignment from './Assignment';
import SeverabilitySurvivability from './SeverabilitySurvivability';
import Agreement from './Agreement';
import BestPriceGuarantee from './BestPriceGuarantee';

const termsItemsElements = [
  {
    title: 'Eligibility',
    text: <Eligibility />
  },
  {
    title: 'Modification of Our Terms and Conditions',
    text: <Modification />
  },
  {
    title: 'Payments',
    text: <Payments />
  },
  {
    title: 'Prices',
    text: <Prices />
  },
  {
    title: 'Cancellations and Alterations Policies',
    text: <Cancellations />
  },
  { title: 'Best Price Guarantee', text: <BestPriceGuarantee /> },
  {
    title: 'Issuing Travel Documents',
    text: <Issuing />
  },
  {
    title: 'Insurance',
    text: <Insurance />
  },
  {
    title: 'Passports, Visas, Health Requirements, and Travel Risks',
    text: <Documents />
  },
  {
    title: 'Accommodations (Hotels) & Car Rentals',
    text: <Accommodations />
  },
  {
    title: 'Notices',
    text: <Notices />
  },
  {
    title: 'Seller of Travel Disclosures',
    text: <TravelDisclosures />
  },
  {
    title: 'Local Customs and Laws',
    text: <Laws />
  },
  {
    title: 'Legal Compliance and Proper Conduct',
    text: <LegalCompliance />
  },
  {
    title: 'Marketing Materials',
    text: <MarketingMaterials />
  },
  {
    title: 'Limitation of Liability',
    text: <LimitationLiability />
  },
  {
    title: 'Disclaimer of Warranties',
    text: <DisclaimerWarranties />
  },
  {
    title: 'Indemnification and Release',
    text: <IndemnificationRelease />
  },
  {
    title: 'Force Majeure',
    text: <ForceMajeure />
  },
  {
    title: 'Special Rules regarding Air Transport',
    text: <AirTransport />,
    otherTitle: 'AIR TRANSPORT'
  },
  {
    title: 'Representations and Warranties',
    text: <Warranties />
  },
  {
    title: 'Medical and Physical Conditions; Medical Emergencies',
    text: <MedicalCondition />
  },
  {
    title: 'Disputes: Governing Law, Jurisdiction, etc.',
    text: <Disputes />
  },
  {
    title: 'Attorney’s Fees, Costs, and Expenses of Suit',
    text: <Expenses />
  },
  {
    title: 'Assignment',
    text: <Assignment />
  },
  {
    title: 'Severability and Survivability',
    text: <SeverabilitySurvivability />
  },
  {
    title: 'Entire Agreement, Waiver, Etc.',
    text: <Agreement />
  }
];

const TermsConditions = () => {
  let maxId = 100;

  const list = termsItemsElements.map(({ title }) => {
    return (
      <li key={maxId++}>
        <a
          href={`#${title
            .toLowerCase()
            .replace(/\s/g, '-')
            .replace(/,/g, '')}`}
        >
          {title}
        </a>
      </li>
    );
  });

  const elements = termsItemsElements.map(({ title, text, otherTitle }) => {
    return (
      <li
        key={title
          .toLowerCase()
          .replace(/\s/g, '-')
          .replace(/,/g, '')}
        id={title
          .toLowerCase()
          .replace(/\s/g, '-')
          .replace(/,/g, '')}
      >
        {otherTitle ? (
          <strong> {otherTitle}</strong>
        ) : (
          <strong>{title} -</strong>
        )}{' '}
        {text}
      </li>
    );
  });

  return (
    <>
      <ol className="nav-list">{list}</ol>
      <ol> {elements} </ol>
    </>
  );
};

export default TermsConditions;
