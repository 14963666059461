import React from 'react';

const MedicalCondition = () => {
  return (
    <>
      <span>
        Medical Emergencies. Some activities available on Travelopod’s Trips are
        physically active and interactive, so you must be in good physical
        condition and health to participate in them. Various water activities
        and sports available on our Trips (including jet skiing, snorkeling,
        surfing, etc.) require various skills and abilities such as: the ability
        to swim, hand to eye coordination, balance, and an awareness of your
        surroundings. Traveler certifies they will not take any alcoholic
        beverages or drugs that may impair their physical or mental abilities
        before their participation in an adventure or water activity while on a
        Trip.
      </span>
      <p>
        Traveler certifies that they are responsible for managing their own
        medication and medical, physical, or allergic conditions during their
        Trip. Traveler understands that in the event of injury to Traveler, or
        exacerbation of Traveler’s medical condition, Travelopod may not be held
        responsible. If a serious emergency arises, it may be necessary for a
        physician to attend to Traveler, but Traveler recognizes that Travelopod
        is not obligated to take any action to facilitate or assist that
        treatment. The quality of medical personnel and facilities vary from
        region to region and cannot be controlled by Travelopod. In some parts
        of the world, substandard medical care is common and unavoidable.
        Hospital facilities are often unavailable and evacuation can be
        prolonged, difficult and expensive. Travelopod is not responsible for
        the costs of any medical treatment you may require during a Trip and
        assumes no liability regarding provision of medical care or lack thereof
        that you may receive while on the Trip. YOU ARE RESPONSIBLE FOR RISKS
        ASSOCIATED WITH, AND COSTS, OF ANY AND ALL MEDICAL TREATMENTS YOU MAY
        REQUIRE OR RECEIVE DURING YOUR TRIP. Traveler releases Travelopod from
        any liability relating to any such medical care, whether secured by a
        Service provider on behalf of Traveler, Travelopod, or otherwise, and
        agrees to be responsible for any and all expenses incurred for said
        medical care.
      </p>
    </>
  );
};

export default MedicalCondition;
