import React from 'react';

const Assignment = () => {
  return (
    <>
      <span>
        Traveler may not assign his rights or obligations hereunder without the
        prior written consent of Travelopod.
      </span>
    </>
  );
};

export default Assignment;
