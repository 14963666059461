import React from 'react';

const Cancellations = () => {
  return (
    <span>
      Our Cancellations and Alterations Policies vary by Service and/or Trip
      sold. They are viewable in writing prior to purchase via email delivered
      to the purchaser. If those are unclear, please talk to one of our agents
      at the time of sale or at any other time prior to purchase.
    </span>
  );
};

export default Cancellations;
