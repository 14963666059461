import React from 'react';

const Insurance = () => {
  return (
    <span>
      Travelopod strongly recommends that all Travelers purchase some form of
      Travel, Cancellation, Lost Baggage and/or Medical Emergency Insurance for
      all Trips. While we can refer you to an insurance company, Traveler
      acknowledges that it is Traveler’s responsibility to understand the
      limitations of their insurance coverage and purchase additional insurance
      as needed. It is the Traveler’s sole responsibility to research, evaluate
      and purchase appropriate coverage. Traveler agrees that Travelopod is not
      responsible for any uninsured losses.
    </span>
  );
};

export default Insurance;
