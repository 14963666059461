import React from 'react';

const Issuing = () => {
  return (
    <>
      <span>
        The method for issuing travel documents and/or tickets depends on the
        time available between the date of issue of tickets and your date of
        departure, and/or the type of Service. If a Traveler provides incorrect
        information, Travelopod does not assume any liability if the Trip is
        adversely affected or made impossible by the non-receipt of travel
        documents.
      </span>
      <p>
        Travel documents will only be sent to the purchasing Traveler who places
        the order and personally agrees to these Terms and Conditions.
      </p>
    </>
  );
};

export default Issuing;
