import React from 'react';

const Warranties = () => {
  return (
    <>
      <span>
        Traveler represents and warrants that Traveler: (i) has all requisite
        power and authority to enter into and perform the obligations under
        these Terms and Conditions; (ii) will abide by all laws, rules and
        regulations and will endeavor to comply with all local customs; and
        (iii) all of the information provided to Travelopod to facilitate
        Traveler’s Trip is true and accurate. Traveler authorizes Travelopod to
        make any such investigations as Travelopod determines to be necessary,
        at their sole discretion, to determine Travelers eligibility.
      </span>
    </>
  );
};

export default Warranties;
