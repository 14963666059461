import React from 'react';

const Disputes = () => {
  return (
    <>
      <span>
        Governing Law, Jurisdiction, etc. These Terms and Conditions and the
        relationship between Traveler and Travelopod will be governed by the
        laws of the State of California without regard to its conflict of law
        provisions.
      </span>
      <p>
        Traveler and Travelopod agree to submit to the personal jurisdiction of
        the federal and state courts located in Santa Clara County, California
        with respect to any legal proceedings that may arise in connection with,
        or relate to, a Trip, these Terms and Conditions, our Privacy Policy,
        Travelopod’s website or any literature or materials concerning
        Travelopod and our Trips. Traveler and Travelopod agree to irrevocably
        submit to the jurisdiction of any such court in any such action, suit or
        proceeding and hereby agrees not to assert, by way of motion, as a
        defense or otherwise, in any such action, suit or proceeding, any claim
        that (i) he, she or it is not subject personally to the jurisdiction of
        such court, (ii) the venue is improper, or (iii) this agreement or the
        subject matter hereof may not be enforced in or by such court.
      </p>
    </>
  );
};

export default Disputes;
