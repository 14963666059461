import React from 'react';

const LimitationLiability = () => {
  return (
    <>
      <span>
        IN NO EVENT SHALL TRAVELOPOD BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT,
        EXEMPLARY, SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES OF ANY KIND,
        INCLUDING WITHOUT LIMITATION, DAMAGES FOR ANY LOSS OF OPPORTUNITY OR
        OTHER PECUNIARY LOSS, EVEN IF TRAVELOPOD HAS BEEN ADVISED OF THE
        POSSIBILITY OR PROBABILITY OF SUCH DAMAGES OR LOSSES, WHETHER SUCH
        LIABILITY IS BASED UPON CONTRACT, TORT, NEGLIGENCE OR OTHER LEGAL
        THEORY. IN NO EVENT SHALL TRAVELOPOD’S TOTAL AGGREGATE LIABILITY TO THE
        TRAVELER FOR CLAIMS ARISING UNDER THIS AGREEMENT EXCEED THE TOTAL
        AMOUNTS PAID BY THE TRAVELER TO TRAVELOPOD UNDER THIS AGREEMENT.
      </span>
      <p>
        TRAVELOPOD IS ACTING AS A MERE AGENT FOR ALL SUPPLIERS OF SERVICES AND
        ACCOMMODATIONS ADVERTISED AND/OR SOLD BY US. ALL SUPPLIERS OF SERVICES
        AND ACCOMMODATIONS ADVERTISED AND/OR SOLD BY TRAVELOPOD ARE THIRD PARTY
        VENDORS AND TRAVELOPOD RETAINS NO OWNERSHIP INTEREST, MANAGEMENT, OR
        CONTROL OF THOSE THIRD PARTY VENDORS. TO THE FULLEST EXTENT PERMITTED BY
        LAW, TRAVELOPOD DOES NOT ASSUME LIABILITY FOR ANY INJURY, DAMAGE, DEATH,
        LOSS, ACCIDENT OR DELAY DUE TO AN ACT OR OMISSION OF ANY THIRD PARTIES
        (INCLUDING THIRD PARTY VENDORS), GOVERNMENTAL AUTHORITY, OR ACTS
        ATTRIBUTABLE TO YOU YOURSELF, INCLUDING, WITHOUT LIMITATION, NEGLIGENT
        OR RECKLESS ACTS, EVEN IF TRAVELOPOD HAS BEEN ADVISED THAT SUCH DAMAGES
        WERE POSSIBLE OR PROBABLE.
      </p>
    </>
  );
};

export default LimitationLiability;
