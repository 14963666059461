import React from 'react';

const TravelDisclosures = () => {
  return (
    <>
      <span>
        California law requires certain sellers of travel to have a trust
        account or bond. Travelopod has a trust account with First Tech Credit
        Union.
      </span>
      <p>
        Travelopod is a participant in the California Travel Consumer
        Restitution Fund (TCRF).
      </p>
      <p>
        This transaction is covered by the TCRF if the seller of travel was
        registered and participating in the TCRF at the time of sale and the
        passenger is located in California at the time of payment. Eligible
        passengers may file a claim with TCRF if the passenger is owed a refund
        of more than $300 for transportation or travel Services which the seller
        of travel failed to forward to a proper provider or such money was not
        refunded to you when required. The maximum amount which may be paid by
        the TCRF to any one passenger is the total amount paid on behalf of the
        passenger to the seller of travel, not to exceed $15,000. A claim must
        be submitted to the TCRF within 12 months after the scheduled completion
        date of the travel. A claim must include sufficient documentation to
        prove your claim and a $35 processing fee. Claimants must agree to waive
        their right to other civil remedies against a registered participating
        seller of travel for matters arising out of a sale for which you file a
        TCRF claim. You may request a claim form by writing to: Travel Consumer
        Restitution Corporation; P.O. Box 6001; Larkspur, CA 94977-6001; or by
        visiting TCRC’s website at:{' '}
        <a href="https://www.tcrcinfo.org/">www.tcrcinfo.org</a>.
      </p>
      <p>
        If Traveler is purchasing from outside of California, this transaction
        is not covered by the California Travel Consumer Restitution Fund.
      </p>
    </>
  );
};

export default TravelDisclosures;
