import React from 'react';

const Payments = () => {
  return (
    <>
      <span>
        I understand if I have an unpaid balance to Travelopod Inc and do not
        make satisfactory payment arrangements, my account may be placed with an
        external collection agency. I will be responsible for reimbursement of
        any fees from the collection agency, including all costs and expenses
        incurred collecting my account, and possibly including reasonable
        attorney’s fees if so incurred during collection efforts. In order for
        Travelopod Inc or their designated external collection agency to service
        my account, and where not prohibited by applicable law, I agree that
        Travelopod Inc and the designated external collection agency are
        authorized to (i) contact me by telephone at the telephone number(s) I
        am providing, including wireless telephone numbers, which could result
        in charges to me, (ii) contact me by sending text messages (message and
        data rates may apply) or emails, using any email address I provide and
        (iii) methods of contact may include using pre-recorded/artificial voice
        message and/or use of an automatic dialing device, as applicable.
        <br /> Travelopod accepts payments by
      </span>
      <ol>
        <li>
          International credit cards including Discover, American Express, Visa
          and MasterCard.
        </li>
        <li>Wire transfer</li>
        <li>Cash deposited directly in our bank.</li>
      </ol>
    </>
  );
};

export default Payments;
