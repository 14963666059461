import React from 'react';

const AirTransport = () => {
  return (
    <>
      <ul>
        <li>
          <p>
            <strong>General conditions governing air transport</strong>
            <br />
            Travelopod’s responsibilities in respect to air travel are limited
            by the relevant airline’s conditions of carriage. The airline
            fulfilling your contract for carriage may change from the airline
            mentioned in our electronic brochure and advertisements. Travelopod
            is not able to specify the type of aircraft to be used by any
            airline. In addition, Travelopod is not responsible for losses due
            to cancelled flights, seats, or changed flight itineraries. Airlines
            retain the right to adjust flight times and schedules at any time;
            schedule changes can result in an itinerary that falls outside of
            contractual agreements. You cannot cancel the contract without
            penalty due to a change of airline, aircraft type or destination. If
            an airline cancels or delays a flight, you should work with the
            airline to ensure you arrive at your destination on or ahead of
            time. Travelopod will not provide any refund for Trips missed, in
            part or full, due to missed, cancelled or delayed flights, or other
            flight irregularities including, without limitation, denied boarding
            whether or not you are responsible for such denial.
          </p>
          <p>
            Direct flights may be “non stop” or may involve one or more
            stop-overs (in the latter case this means the same flight by the
            airline, because the flight number remains the same). The same
            applies to connecting flights that may be subject to crew changes.
            When you reserve a scheduled or charter flight involving a stop-over
            in a town, and the second flight takes off from a different airport
            to the airport of arrival, ensure that you have sufficient time for
            reaching the second airport. The journey to the other airport is at
            your own expense. TRAVELOPOD will not be able to reimburse you for
            these costs, nor will it be liable if you miss the second flight.
          </p>
          <p>
            Travelopod will not bear any liability, particularly in the event of
            timetable changes, delays, cancellations, etc., attributable to
            force majeure (labor dispute, strikes, storms, wars, earthquakes,
            epidemics, etc.) or in the event that, after the specified arrival
            time at the airport, it is found that the customer is refused
            boarding for failure to comply with the administrative or health
            formalities, or failure to check-in.
          </p>
        </li>
        <li>
          <p>
            <strong>Problems related to the issuance of e-tickets</strong>
            <br />
            As of June 1st, 2008, the International Air Transport Association
            (IATA) has imposed new rules with regard to the issuing of air
            travel tickets. As of that date, travel agencies and airlines have
            an obligation to only issue travel tickets via electronic means
            (i.e. electronic ticket or “e-ticket”).
          </p>
          <p>
            Due to technical constraints to do with airline’s restrictions in
            relation to certain requirements (infants under the age of 2,
            inter-airline agreements, groups, etc.), it may be impossible to
            issue an electronic ticket. Therefore, though a flight may be shown
            as available, it might prove impossible for us to honor your
            reservation. This situation, which is outside our control, will not
            result in liability on our part.
          </p>
          <p>
            If we cannot issue you an e-ticket, we will contact you to propose
            an alternative route solution. This could involve a different tariff
            and/or additional costs for which you would be responsible. In the
            event of the absence of an alternative solution, your refusal to pay
            any tariff difference, or if the issuance of tickets proves
            impossible, we would be forced to cancel your reservation at no cost
            to you. We will provide you with a full refund within 30 days after
            determining that there is no alternative solution possible.
          </p>
        </li>
        <li>
          <p>
            <strong>Failure to check-in</strong>
            <br />
            Failure to check-in for a flight on the outward journey (on a
            charter or scheduled flight) will automatically result in
            cancellation of the return flight by the airline. We would encourage
            you to contact us on the date of departure if you wish us to keep
            the return flight open; this decision remains at the discretion of
            the airline company.
          </p>
          <p>
            Any interrupted or shortened journey, or any service that you do not
            take up, will not entitle you to a refund. If you have taken out
            insurance coverage, and in particular insurance for the interruption
            of a stay, you must comply with the procedures for cancellation
            appearing in your insurance contract.
          </p>
        </li>
        <li>
          <p>
            <strong>Flight connections</strong>
            <br />
            If any booked flight connecting with your outbound or inbound flight
            is cancelled or delayed, the airlines reserve the right to provide
            that transport by any other means (coach/bus, train, etc.).
          </p>
          <p>
            If you organize your own connecting transport with the arrangements
            booked with Travelopod, we would advise that you reserve flexible or
            refundable tickets in order to avoid the risk of any financial loss.
            You are also advised not to make any important appointments for the
            day following your return date.
          </p>
          <p>
            Travelopod cannot accept responsibility for the consequences of
            delays (such as a cancelled scheduled flight) in the context of
            connecting transport organized by you.
          </p>
        </li>
        <li>
          <p>
            <strong>The return</strong>
            <br />
            Whatever the type of flight, scheduled or charter, it is essential
            that the return be re-confirmed locally with the airline within 72
            hours prior to the envisaged date of departure. For package Trips,
            this formality is generally performed by the local representative or
            agent. We would draw your attention to the fact that this procedure
            is compulsory and that, if you fail to do so, your seat cannot be
            guaranteed by the airline, which has the right to allocate your seat
            to someone else. In addition, this procedure also gives you the
            opportunity to confirm the times of your return flight which may
            have been altered in the meantime. Travelopod cannot be held liable
            for any negligence on your part in failing to re-confirm your return
            flight.
          </p>
        </li>
        <li>
          <p>
            <strong>Luggage</strong>
            <br />
            Travelopod assumes no liability for any loss or damage to baggage or
            personal effects, whether in transit to or from a Trip, or during a
            Trip. The airline is liable to you for the baggage you entrust to it
            only for the compensation contemplated in the international
            conventions and relevant statutes. In the event of damage, late
            forwarding, theft or loss of luggage, you should contact the your
            airline and declare the damage, absence or loss of your personal
            effects before leaving the airport, and then submit a declaration,
            attaching the originals of the following documents: the travel
            ticket, the baggage check-in slip, and the declaration. It is
            recommended that you take out an insurance policy covering the value
            of your items.
          </p>
          <p>
            Additional and oversized baggage fees: Most airlines have their own
            policy regarding luggage. We recommend that you check with your
            airline ahead of time for any weight restrictions and additional
            charges relating to checked baggage. You will be responsible for
            paying to the airline any additional charges for checked or
            overweight baggage, including, but not limited to, golf bags and
            oversized luggage. If you exceed the weight limit set by your
            airline, and excess weight is permitted, you must pay a supplement
            directly to the airline at the airport.
          </p>
        </li>
        <li>
          <p>
            <strong>Babies and infants</strong>
            <br />
            Babies (up to 2 years of age) do not occupy a seat; the price of the
            ticket is generally 10% of the official rate. On certain flights
            children (from 2 to 11 years of age) may be granted a reduction,
            except on charter flights.
          </p>
        </li>
        <li>
          <p>
            <strong>Pregnancy</strong>
            <br />
            Different airlines have their own restrictions on when pregnant
            woman may fly on their plane, which can range from prohibiting
            flying anywhere from 7 to 30 days by the due date. It is your
            responsibility to check the restrictions of your particular airline.
            If you are denied boarding, Travelopod will not be responsible for
            any resulting cancellation fees and charges.
          </p>
        </li>
      </ul>
    </>
  );
};

export default AirTransport;
