import React from 'react';

const MarketingMaterials = () => {
  return (
    <>
      <span>
        Travelopod endeavors to illustrate the Services it offers using
        photographs or illustrations that provide a realistic representation of
        the Services offered. However, please note that photographs and
        illustrations appearing in descriptions are for illustrative purposes
        only. They are binding on Travelopod only to the extent that they
        illustrate the type or standard of such Services, and are not
        contractual nor are they to be construed as guarantees of the conditions
        of the places or Accommodations pictured at the time of your Trip.
      </span>
      <p>
        The travel information that we provide you on our site and in
        newsletters, brochures and the like about options for Accommodations,
        excursions, restaurants, bars/clubs, etc. comes from a variety of
        sources, including information published by the establishments
        themselves, by other travel guides, and by on-line research, and is
        believed to be accurate. Nonetheless, some of the information could not
        be independently verified and may be inaccurate and not up to date. You
        should not view the information as recommendations, and should do your
        own investigation to confirm that the business/site in question is still
        operational and suits your needs.
      </p>
      <p>
        Travelopod may occasionally use statements made by its Travelers and/or
        their photographs, images, or other likenesses, in various marketing
        materials, on our website, or at promotional events. Traveler fully
        consents to such use of Traveler’s statements and/or their photographs,
        images, or other likenesses, for marketing or promotional purposes
        without the payment of any compensation to Traveler and grants
        Travelopod a non-revocable license for said use.
      </p>
    </>
  );
};

export default MarketingMaterials;
