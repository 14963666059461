import React from 'react';

const LegalCompliance = () => {
  return (
    <>
      <span>
        Traveler understands that their participation in a Trip may be
        terminated if Traveler is disciplined by any civil or criminal
        authorities, charter operator, or if Travelopod, in its sole discretion,
        determines that Traveler's conduct is incompatible with the interests,
        safety or welfare of other Travelers or Service providers, their
        employees, or independent contractors. In this event, Traveler is
        responsible for arranging and paying for substitute travel and
        Accommodations.
      </span>
    </>
  );
};

export default LegalCompliance;
