import React from 'react';

const Eligibility = () => {
  return (
    <span>
      The Services offered by Travelopod are available for purchase by residents
      of the United States while in the United States, its territories,
      possessions, and protectorates, who have all the requisite power and
      authority to enter into and perform the obligations under these Terms and
      Conditions. Travelers must be over the age of 18 to purchase a Travelopod
      Trip.
    </span>
  );
};

export default Eligibility;
