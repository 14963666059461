import React from 'react';

const Agreement = () => {
  return (
    <>
      <span>
        These Terms and Conditions constitute the entire understanding and
        agreement of the parties with respect to the subject matter covered by
        them, and supersede all prior and contemporaneous understandings and
        agreements, whether written or oral, with respect to such subject
        matter. No terms contained on any proposal, purchase order,
        acknowledgment or other document will be effective with respect to
        affecting the terms hereof. No delay or failure by either party to
        exercise or enforce at any time any right or provision hereof will be
        considered a waiver thereof of such party's rights thereafter to
        exercise or enforce each and every right and provision hereof. No single
        waiver will constitute a continuing or subsequent waiver. Travelopod
        does not guarantee it will take action against all breaches of these
        Terms and Conditions. No waiver, modification or amendment of any
        provision hereof will be effective unless it is in a writing signed by
        both the parties.
      </span>
    </>
  );
};

export default Agreement;
