import React from 'react';

const Expenses = () => {
  return (
    <>
      <span>
        If any act of law or equity, including an action for declaratory relief
        or any arbitration proceeding, is brought to enforce, interpret or
        construe the provisions of these Terms and Conditions, a Trip, our
        Privacy Policy, Travelopod’s website or any literature or materials
        concerning Travelopod, the prevailing party shall be entitled to recover
        actual reasonable attorney’s fees, costs, and expenses.
      </span>
    </>
  );
};

export default Expenses;
