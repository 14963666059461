import React from 'react';

const IndemnificationRelease = () => {
  return (
    <>
      <span>
        Except as otherwise set forth herein, Traveler hereby releases
        Travelopod from any and all liability, loss, expense, damages, or claims
        arising out of or resulting from Traveler’s participation in a Trip,
        whether caused by the negligent, intentional, or reckless conduct of
        Traveler, a Service Provider, a provider of Accommodations, another
        third party, or otherwise.
      </span>
      <p>
        Traveler hereby also agrees to indemnify, defend and hold harmless
        Travelopod from and against any and all damages, losses, claims,
        liabilities, deficiencies, costs, fees (including reasonable attorneys'
        fees) and expenses, arising out of any claim brought against Travelopod
        regarding, resulting, or arising from Traveler’s participation in a Trip
        or Traveler’s performance of this Agreement.00
      </p>
    </>
  );
};

export default IndemnificationRelease;
