import React from 'react';

const Accommodations = () => {
  return (
    <>
      <span>
        “Accommodations” are any lodgings in a dwelling or similar living
        quarters afforded to Travelers including, but not limited to, hotels,
        quarters in ships and charters, camp grounds, motels, and resorts.
        Travelopod provides the accommodations for its Trips through third-party
        Vendors and retains no ownership or management interest in those
        Accommodations. Travelopod does not guarantee the location or the
        amenities of the Accommodations nor the performance of the third-party
        Vendors. If any issues arise, please contact the owner/operators of the
        respective Accommodations directly.
      </span>
      <p>
        Prices of Accommodations are based on double occupancy unless described
        otherwise. If you prefer single Accommodations, some Accommodations
        require you to pay a single supplement fee which can vary depending on
        the Accommodation. Please contact us for specifics on the single
        supplement fees for each Trip.
      </p>
      <p>
        <b>For Hotel &amp; Car rental bookings</b>
        <br />
        The reservation holder must present a valid photo ID and credit card at
        check-in. The credit card is not only used to guarantee the reservation,
        it is required for any additional hotel specific service fees or
        incidental charges or fees that may be charged by the hotel at checkout.
        These charges may be mandatory (e.g., resort fees) or optional (parking,
        phone calls or minibar charges) and are not included in your per night
        price. The renter of the vehicle must be present to sign the rental
        agreement and provide their credit card at the time of pick up. The
        renter cannot provide a credit or debit card belonging to someone else
        for their use.
      </p>
    </>
  );
};

export default Accommodations;
