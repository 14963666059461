import React from 'react';

const Notices = () => {
  return (
    <>
      <span>
        Any notices required or permitted hereunder shall be given via email to
        : <a href="mailto:Hello@travelopod.com">Hello@travelopod.com</a>
      </span>
    </>
  );
};

export default Notices;
