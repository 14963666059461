import React from 'react';

const Prices = () => {
  return (
    <>
      <span>
        <strong>Our prices are contractual tariffs -</strong>
        No claim relating to the price of a Trip will be considered once the
        reservation is effective. Unless otherwise stated in the description of
        the offer, the following will NOT be included as part of the advertised
        price:
      </span>
      <ul>
        <li>items of a personal nature such as incidentals and laundry</li>
        <li>excess baggage charges</li>
        <li>port charges</li>
        <li>foreign government departure taxes</li>
        <li>costs of Passports or Visas</li>
        <li>inspection fees</li>
        <li>customs fees</li>
        <li>immigration and naturalization fees</li>
        <li>service fees</li>
        <li>single room supplement</li>
        <li>
          travel insurance including health, accidents, lost baggage and trip
          cancellation
        </li>
        <li>airport parking</li>
        <li>tips and gratuities</li>
        <li>
          any other activities or items not explicitly advertised as included in
          the price of a Trip in the description of the offered Trip
        </li>
      </ul>
    </>
  );
};

export default Prices;
