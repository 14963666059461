import React from 'react';

const Modification = () => {
  return (
    <span>
      Our Terms and Conditions may be amended or modified by us at any time,
      without notice, on the understanding that such changes will not apply to
      Trips booked prior to the amendment or modification. It is therefore
      essential that you consult and accept our Terms and Conditions at the time
      of making a booking, particularly in order to determine which provisions
      are in operation at that time in case they have changed since the last
      time you placed an order with Travelopod or reviewed our Terms and
      Conditions.
    </span>
  );
};

export default Modification;
